(function ($) {

  $('.recruit-slide-item').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    centerMode: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '25px',
        }
      }
    ]
  });

  const cursor = document.getElementById('cursor');
  const follower = document.getElementById('follower');
  if (!cursor || !follower) {
    return;
  }

  const pos = { x: 0, y: 0 };
  const mouse = { x: pos.x, y: pos.y };
  const speed = 0.5;

  const cursorSetX = gsap.quickSetter(cursor, "x", "px");
  const cursorSetY = gsap.quickSetter(cursor, "y", "px");
  const followerSetX = gsap.quickSetter(follower, "x", "px");
  const followerSetY = gsap.quickSetter(follower, "y", "px");

  document.addEventListener("mousemove", function (event) {
    mouse.x = event.pageX;
    mouse.y = event.pageY;
  });

  gsap.ticker.add(function () {
    var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;
    cursorSetX(pos.x);
    cursorSetY(pos.y);
    followerSetX(pos.x);
    followerSetY(pos.y);
  });


  const slickTrack = document.querySelector('.slick-list');
  slickTrack.addEventListener('mouseenter', () => {
    if(!follower.classList.contains('show')) {
      follower.classList.add('show');
    }
  });

  slickTrack.addEventListener('mouseleave', () => {
    if(follower.classList.contains('show')) {
      follower.classList.remove('show');
    }
  });

})(jQuery);